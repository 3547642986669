import Footer from './footer';
import Header from './header';

const TermsCondition = () => {
  return (
    <div className="termsCondtion">
      <title> Terms and Conditions | Vyanjan-Pursuit for Hunger! </title>
      <Header />
      <div className="container page-container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h2>Vyanjan Terms Of Service</h2>
              <img src="assets/images/title-line.png" alt="title-line" className="img-fluid" />
            </div>
            <p>Greetings from Vyanjan. The terms that apply when you order any menu items using our
              mobile application.</p>
            <p>Before creating a Vyanjan account or using our application, please read these Terms
              carefully. Please email vyanjan12@gmail.com if you have any questions about these terms.
              When you order Items through our Application, you have a few legal rights if you're a
              consumer. These Terms apply in addition to and not as a substitute for your legal rights and
              in no way impact them. By creating a Vyanjan account, you agree to these Terms.</p>
            <div className="blog-hover">
              <ol type="1">
                <h4><li>Getting to Know Us</li></h4>
                <p >
                  G & S Pvt Ltd, a business formed and registered in India, runs Vyanjan. The registration
                  number for our company is 08167130. 286 7364 55 is our VAT number. You can reach us
                  via email at vyanjan12@gmail.com or through our website’s contact us page.
                </p>
                <h4><li>Goal</li></h4>
                <p>
                  We want to make it easier for you to order items for delivery by bridging the gap between
                  local food makers and the customers we serve. When you place an order with a food maker,
                  Vyanjan works as their agent to complete the order through our application and to oversee
                  your experience as it progresses. Delivery of your Items will be coordinated after you have
                  placed an order.
                </p>
                <h4><li>Your Account</li></h4>
                <p>You must create a Vyanjan account before you can place orders for Items using our
                  application. When you open an account, you might be required to give your card information
                  for payment as well as a password or another secure login method. You must keep any
                  password or other secure login information you choose private, and you must limit access to
                  your email and mobile devices. You are responsible for paying for any things that someone
                  else orders using these methods to access your account, and we are not liable for any
                  additional losses you incur, unless the person using your password was able to do so
                  because we failed to keep it secure.</p>
                <p>You can request to close your account at any moment via the application&#39;s &quot;your account&quot;
                  area or by getting in touch with us via the provided information. If we feel that someone else
                  has been using your account, we may restrict your access to it or close it permanently.
                  Additionally, if we feel you are abusing our service in any way—for instance, by repeatedly
                  filing unreasonable complaints, berating our employees or delivery drivers, ordering large
                  quantities of potentially hazardous goods, or for any other valid reason—we reserve the right
                  to close your account. Any remaining account credit that you have legitimately received from
                  us will be refunded if we permanently cancel your account. If there is a problem with an
                  order, our customer support team or application will attempt to resolve it by issuing a credit
                  to your registered credit card, or if that is not feasible for any reason, by transferring money
                  using your specified bank information (assuming you have given us those data).</p>
                <h4><li>Availability of Services</li></h4>
                <p>Each Food Maker is a native of the corresponding region. Due to variables like the weather
                  or the demand for our service, this delivery area may vary at any time. This will guarantee
                  that the Items are at their finest when they arrive at your home. Our food makers choose
                  their own business hours. This implies that the Food Makers in your area determine whether
                  our Service is offered and the variety of Food Makers from which you can place orders. If
                  you attempt to request a delivery to a location outside of a Food Maker's delivery zone or
                  business hours, or if the Application is otherwise not accessible for any reason, we will let
                  you know that your order will not be fulfilled.</p>
                <h4><li>Orders</li></h4>
                <p>To use our service, you must be at least 18 years old. By using our application to place an
                  order, you certify that you are at least 18 years old. Orders placed through our application
                  must first be approved by the food maker in order for them to be confirmed. If your order is
                  accepted, we&#39;ll let you know by sending you a notification. Even if you ordered the item for
                  someone else, you are still liable for paying for it, for any delivery fees associated with it, and
                  for abiding by these Terms. Everything is based on availability. Some foods may be
                  prepared by food makers with nuts or other allergies. If you have an allergy and that
                  information is not available or you have more questions, please contact the Food Maker
                  before placing your order. No item offered by our food makers is guaranteed to be allergen-
                  free by Vyanjan.</p>
                <h4><li>Delivery</li></h4>
                <p>You will know the anticipated delivery time for your item when you place an order, but we will
                  make every effort to deliver as soon as possible. As a result, you must be accessible to take
                  delivery as soon as the order is placed. It&#39;s critical that you constantly check our app for the
                  most up-to-date delivery times because they can change according to a variety of factors,
                  including meal preparation time, traffic and weather conditions, and the availability of the
                  delivery person.</p>
                <p>Unfortunately, despite our best efforts and those of our food maker, things do not always go
                  according to plan, and delays may prohibit us from delivering your item on time. We will work
                  with you to make things right unless you caused the delay (for example, because you gave
                  us the wrong address or failed to come to the door), in which case we will work with you to
                  make things right if your order is more than 15 minutes later than the time we gave you when
                  you placed the order and we haven't notified you giving you the option to cancel your order.</p>
                <p>When you place an order, we'll make an attempt to deliver to the address you give us. You
                  can choose to cancel your order if we are unable to change the delivery address, but once
                  food preparation has begun, you will be charged the entire price of the item and the delivery
                  fee if the delivery person has been dispatched.</p>
                <p>If a delivery is unsuccessful for whatever reason that you caused, you will still be charged for
                  both the item and delivery. You could fail a delivery for a variety of reasons, including but not
                  restricted to:</p>
                <ul>
                  <li><p>When the delivery person called you using the contact information you provided, you
                    did not answer the phone and did not come to the door.</p></li>
                  <li><p>In compliance with section 8 (Age Restricted Products), the delivery person declines
                    to give you the Item.</p></li>
                </ul>
                <h4><li>Your Rights if Your Items Have a Problem</li></h4>
                <p>You have a legal right to obtain goods that are as described, satisfactory in quality, and meet
                  any special criteria you inform us of (and we accept) prior to placing your order. Please let us
                  know if you think the items we sent to you violate any of these legal rights. If the issue can
                  be observed by inspecting the Items, we might ask for a picture of the issue. Unless we have
                  good reason to suspect that the issue was created after delivery, we will issue a refund oraccount credit for the impacted portion of the Item as well as for shipping if the entire order
                  was affected.</p>
                <p>We may consider pertinent elements, such as the order details, your account history, what
                  transpired during delivery, and information from the Food Maker, before issuing your refund
                  or account credit.</p>
                <h4><li>Products with Age Restrictions and Regulations</li></h4>
                <p>Only those who are 18 or older may purchase and receive deliveries of age-restricted
                  products. You certify that you are at least 18 years old by placing an order for a product that
                  has an age restriction. Customers ordering age-restricted products from Vyanjan will be
                  required by the delivery person to show identification proving they are at least 18 years old
                  before the delivery is completed. Unless a person can show a valid photo ID confirming they
                  are 18 or older, the delivery person may refuse to deliver any age-restricted merchandise to
                  them. If you choose not to accept delivery of any age-restricted items, you will still be
                  charged for both the item in question and the delivery.</p>
                <h4><li>Order Cancellation</li></h4>
                <p>Before the food maker accepts and begins to prepare the food, you are always free to cancel
                  an order. Please use our Application to cancel an order if you want to do so before it is
                  accepted and confirmed by the food maker. We will reimburse your payment (without any
                  discount or voucher that was applied to the order; see the terms for vouchers and account
                  credit for more information) if the Food Maker confirms the order has not started. If you
                  change your mind about an order after a Food Maker has accepted it, you will be charged
                  the full price of the Items and, if the delivery person has already been sent, the delivery fee
                  as well.</p>
                <p>You could get a notification at any time that an order has been cancelled from Vyanjan or the
                  Food Maker. If we or the Food Maker cancel an order, you won't be charged, and we'll
                  refund any money you've already paid using the same payment method you used to place
                  the purchase. We might also provide you credit on your account to make up for the
                  inconvenience.</p>
                <h4><li>Offers, Prices, and Payment</li></h4>
                <p>Prices including VAT. You attest that you are using our service for personal, non-commercial
                  purposes. When Vyanjan uses dynamic pricing, the price of the Items and delivery could
                  change as you explore. Additionally, prices are subject to change at any time at the Food
                  Maker's discretion. For the delivery of our services, we retain the right to impose a Service
                  Fee that may change. Prior to making a purchase, you will be made aware of any applicable
                  Service Fees and taxes on our Application's checkout page. Existing confirmed orders won't
                  be impacted by revisions, barring blatant pricing errors. No orders that are now being
                  processed or appearing will be impacted by price adjustments.</p>
                <p>Any orders that are already being processed and showing up in your cart will not be
                  impacted by price changes. If there is an obvious pricing error, you will be offered the choice
                  to confirm the transaction at the original price or to cancel it without charge and receive a full
                  refund of any money you have already spent. You might also be charged a delivery fee in
                  cases where Vyanjan or another food maker makes a delivery. This will be made
                  known.Before you finish placing your order, you will be informed of this during the order
                  process.</p>
                <p>The checkout screen on our application will list the complete cost of your order, which
                  includes the prices of the Items and Delivery as well as any applicable Service Fees and
                  Taxes.</p>
                <p>Credit or debit cards, as well as any other payment option made available by Vyanjan, may
                  be used on our Application to pay for all Deliveries and Items. Your credit or debit card will
                  be authorised and the entire amount will be marked for payment once your order has been
                  confirmed. Payment is made straight to Vyanjan, who is acting as the Food Maker Only&#39;s
                  agent. Vouchers and account credit are other payment options. These must be used in
                  accordance with Vyanjan&#39;s account credit and voucher policies.</p>
                <h4><li>Tips</li></h4>
                <p>When Placing Your Order:</p>
                <p>When placing an order, you will be given the choice of adding a tip to the cost of the Items in
                  your order as well as to the delivery person or the Food Maker. Your delivery person or the
                  Food Maker will receive 100% of whatever money you decide to make, and Vyanjan will
                  collect it using the same payment method as the initial transaction.</p>
                <p>Once your order has been delivered:</p>
                <p>You can get a notice allowing you to tip the delivery person. The payment of the tips shall be
                  regarded as a direct payment from you to the delivery person. Vyanjan will collect money on
                  behalf of the delivery person as their limited payment collecting agency. Any payment you
                  choose to make will go entirely to your delivery person. This payment is non-refundable and
                  does not become a part of your order because it is made after you get your order. Your tip
                  can appear as a separate payment on your bank statement or credit card statement,
                  depending on the type of payment that was used for your initial order.</p>
                <p><b>Note :</b> Please take note that we will only accept digital tips. Tips in cash are not accepted.</p>
                <h4><li>Our Liability for Any Loss or Damage You Experience</li></h4>
                <p>If we violate these Terms or fail to use reasonable care and skill in connection with your use
                  of our Service, you may hold us liable for any loss or damage that you sustain that was
                  reasonably foreseeable. Any loss or harm that is not reasonably foreseeable is not our
                  responsibility. Loss or damage is &quot;foreseeable&quot; if it is either evident that it will occur or if you
                  have warned us that it would, as in the case when you inform us prior to placing an order of
                  specific conditions that could exacerbate the loss or damage resulting from our violation of
                  these Terms.</p>
                <p>Where it would be illegal to do so, we do not restrict or exclude our liability to you for loss or
                  damage. This covers any liability for fraud, false representation, violation of your legal rights
                  with regard to the Items, as outlined in part 7 above, or for defective Items under the
                  Consumer Protection Act, as well as any liability for death or personal injury brought on by
                  our failure to use reasonable care and skill or the failure of our employees, agents, or
                  subcontractors. With the exception of the failure of our Applications, we are not liable for any
                  loss or damage you incur as a consequence of your own violation of these Terms or as a
                  result of any IT hardware or software failure.</p>
                <h4><li>Data Protection</li></h4>
                <p>We handle your personal information in accordance with our privacy statement, which is
                  available here.</p>
                <h4><li>Related Terms</li></h4>
                <p>If either you or we have the ability to enforce these terms against the other, that ability will
                  remain intact even if that person delays exercising it or waives it altogether. The rest of the
                  terms shall continue in effect even if a court or other authority finds that any provision of
                  these Terms is invalid or unenforceable.</p>
                <p>From time to time, we might modify these Terms. We will let you know if we make any
                  changes that have an impact on your rights in regard to our service. Any orders you have
                  placed for which we have issued the Confirmation Notice will not be impacted by changes to
                  the Terms. You may file a lawsuit in court about our Service, which is subject to the laws that
                  govern these Terms.</p>
              </ol>
            </div>
            <div className="section-Midtitle">
              <h3>Vyanjan Terms Of Use For Website And
                Applications</h3></div>
            <p>Last updated: 13 September 2021</p>
            <p>This page, along with the documents it refers to, outlines the conditions under which you
              may use our website, vyanjaners.com (hereinafter referred to as "Site"), or any application
              we make accessible through app stores or other distribution channels (hereinafter referred to
              as "Service"), whether you are a registered user or a guest. Before using our site or service,
              please carefully read these terms of use. You acknowledge that you have read, understood,
              and agree to be bound by these terms of use by accessing or using our Site or Service. Do
              not use our site or use our service if you do not agree to these terms of usage.</p>
            <div className="blog-hover">
              <ol type="1">
                <h4><li>Getting to Know Us</li></h4>
                <p>G & S Pvt Ltd, a business formed and registered in India, runs Vyanjan. The registration
                  number for our company is 08167130. 286 7364 55 is our VAT number. You can reach us
                  via email at vyanjan12@gmail.com or through our website’s contact us page.</p>
                <h4><li>Making use of our services or products</li></h4>
                <p>We reserve the right to revoke or change access to our Site or Service without prior notice
                  (see below). Access to our Site and Service is only authorised on a temporary basis. To use
                  our site and service, you must be at least 18 years old. If for any reason our site or service is
                  inaccessible at any time or for any length of time, we will not be held responsible. On
                  occasion, we might only allow users who have registered with us access to certain portions
                  of our site, our service, or both. You are in charge of protecting the privacy of your login
                  information and any actions taken using your account.You should notify us right away if you
                  have any concerns about your login information or believe it has been abused by sending an
                  email to vyanjan12@gmail.com. Your account may be deactivated at any moment.</p>
                <h4><li>Appropriate Use</li></h4>
                <p>Only legitimate uses are permitted for our service. You are not permitted to send, knowingly
                  receive, upload, download, use, or re-use any material that does not adhere to our content
                  standards in clause 5 below, or to use our Site or Service in any way that violates any
                  relevant local, national, or international law or regulation. Additionally, you promise not to
                  gain unauthorised access to, interfere with, harm, or disrupt any portion of our Site, our
                  Service, or any network or equipment utilised to deliver our Service.</p>
                <h4><li>Content Guidelines</li></h4>
                <p>These content guidelines apply to all content (the &quot;Contributions&quot;) that you submit for our
                  service as well as any interactive services that may be offered in conjunction with it. Both the
                  letter and the essence of the following guidelines must be followed. Each component of any
                  Contributions is subject to the same requirements as the whole. Contributions must adhere
                  to all applicable laws in the respective country and in any nation from which they are sent, be
                  truthful when stating facts or opinions, and be honestly held. Contributions may not:</p>
                <ul>
                  <li><p>contain any content that promotes sexually explicit behaviour, violence, or
                    discrimination against people based on their race, gender, religion, nationality,
                    disability, sexual orientation, or age;</p></li>
                  <li><p>violates the copyright, database right, or trademark of another person;</p></li>
                  <li><p>is likely to deceive people; or violates any legal obligation owed to a third party, such
                    as a contractual duty or a duty of good faith.</p></li>
                  <li><p>to harass, disturb, embarrass, alarm, or annoy any other person; to abuse, violate, or
                    intrude upon another&#39;s privacy; to cause annoyance, inconvenience, or undue
                    anxiety;</p></li>
                  <li><p>be employed to encourage, support, or facilitate any illegal behaviour,</p></li>
                  <li><p>copyright infringement or computer misuse; or to misrepresent your name or
                    relationship with any person or give the idea that they originate from us, if such is not
                    the case.</p></li>
                </ul>
                <h4><li>Suspension And Termination</li></h4>
                <p>Failure to comply with section 3 (Acceptable Use) and/or 4 (Content Guidelines) in these
                  Terms of Use constitutes a material breach of the Terms of Use, and may result in our taking
                  all or any of the following actions:</p>
                <ul>
                  <li><p>your right to use our Service will be immediately, temporarily, or permanently
                    revoked;</p></li>
                  <li><p>removal of any posting or content you have uploaded to our service immediately,
                    either temporarily or permanently; warning of you;</p></li>
                  <li><p>legal action against you, including actions to recover all costs associated with the
                    breach (including but not limited to reasonable administrative and legal fees);</p></li>
                  <li><p>dissemination of information to law enforcement officials that we deem essential,
                    based on our best judgement.</p></li>
                </ul>
                <p>The responses set forth in this clause are merely examples; we reserve the right to take
                  whatever other steps seem reasonable in the circumstances.</p>
                <h4><li>Rights to intellectual property</li></h4>
                <p>All intellectual property rights in our Site, Service, and published content (apart from your
                  Contributions) are owned by us or are under licence to us. These works are protected by
                  copyright laws and agreements in every country. Each of these rights is reserved. You are
                  not allowed to use any of the content on this website in any way other than for your own
                  personal, non-commercial use. This includes copying, reproducing, republishing,
                  downloading, posting, broadcasting, and transmitting.</p>
                <h4><li>Information Dependence</li></h4>
                <p>Commentary and other materials posted on our service are not meant to be taken as advice
                  that should be trusted. As a result, we are not responsible or liable for any loss or damage
                  caused by anyone's reliance on any such information whether they are customers of our
                  service or not.</p>
                <h4><li>Regular changes to our Website and Service</li></h4>
                <p>Our website and service are continuously updated, but the material is always subject to
                  change. We have the right to temporarily or permanently restrict access to our site and
                  service depending on the situation. Any content on our site or in our service could be
                  outdated at any point, and we have no duty to keep it that way.</p>
                <h4><li>Our Responsibility</li></h4>
                <p>Our Site and Service have been meticulously prepared by us. However, we disclaim all
                  liability for any errors or omissions in such content as well as for any technical issues you
                  may have with our site or service. If we become aware of any errors on our website or in our
                  service, we&#39;ll make every effort to fix them as quickly as we&#39;re able. To the fullest extent
                  permissible by law, we disclaim all responsibility (whether in contract, tort, or otherwise) for
                  any loss or harm you or any third party may experience as a result of using our Site, Service,
                  any website we may link to, or any content we may publish there. This has no bearing on our
                  responsibility for wrongful death or personal harm resulting from our carelessness, for
                  fraudulent misrepresentation or misrepresentation as to a material fact, or for any other
                  responsibility that cannot be avoided or curtailed by applicable law.</p>
                <h4><li>Data collected from users of our service and website.</li></h4>
                <p>As a result of your use of our Service, we obtain certain information about you. Our privacy
                  policy goes into more detail about this.</p>
                <h4><li>Uploading Content to Our Service And Website</li></h4>
                <p>You acknowledge and agree that any content you upload to our Service or data we gather in
                  accordance with Section 10 above will be regarded as non-confidential and non-proprietary,
                  and that we are free to use, copy, distribute, sell, and disclose to third parties any such
                  content or data for any purpose related to our business. You grant us a perpetual,
                  worldwide, royalty-free licence to use, copy, alter, distribute, sell, and disclose to third parties
                  any such content or data for any purpose relating to our business, to the extent that such
                  material is protected by intellectual property rights.</p>
                <h4><li>Links to External Sites</li></h4>
                <p>When our website provides links to third-party websites and resources, those links are just
                  there for your information. We disclaim all responsibility for any loss or damage that could
                  arise from your use of those resources as we have no control over the content included
                  online.</p>
                <h4><li>Jurisdiction And Applied Law</li></h4>
                <p>Any claim resulting from or relating to a visit to our site or use of our services must be
                  brought in courts of the respective country.The law of the country shall govern these
                  conditions of use and any controversy or claim arising out of or related to them, their subject
                  matter, or their development (including non-contractual disputes or claims).</p>
                <h4><li>Changes</li></h4>
                <p>We reserve the right to update this website and change these terms of use at any time. You
                  are required to periodically check this page to be aware of any updates we make because
                  they are legally binding on you.</p>
                <h4><li>Your Questions</li></h4>
                <p>Contact vyanjan12@gmail.com if you have any questions regarding any of the content that
                  appears on our service.</p>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div >
  )
}

export default TermsCondition