import { useRoutes } from 'react-router-dom';
import Landing from './components/Landing';
import Policy from './components/PrivacyPolicy';
import RefundPolicy from './components/RefundPolicy';
import TermsCondition from './components/TermsCondition';
import ShippingPolicy from './components/shippingPolicy';
import VerifyEmail from './components/VerifyEmail/verifyEmail/VerifyEmail';
import SeekerDeleteAccount from './components/DeleteAccount/SeekerDeleteAccount/SeekerDeleteAccount';
import MakerDeleteAccount from './components/DeleteAccount/MakerDeleteAccount/MakerDeleteAccount';
import DeleteAccount from './components/DeleteAccount/DeleteAccount/DeleteAccount';

export default function Router() {
    const routes = useRoutes([
        {
            path: '',
            element: <Landing />,
        },
        {
            path: 'privacy-policy',
            element: <Policy />,
        },
        {
            path: 'terms-and-conditions',
            element: <TermsCondition />,
        },
        {
            path: 'refund-policy',
            element: <RefundPolicy />,
        },
        {
            path: 'shipping-policy',
            element: <ShippingPolicy />,
        },
        // {
        //     path: 'customer-delete-account-guide',
        //     element: <SeekerDeleteAccount />,
        // },
        // {
        //     path: 'partner-delete-account-guide',
        //     element: <MakerDeleteAccount />,
        // },
        {
            path: 'delete-account-guide',
            element: <DeleteAccount />,
        },
        {
            path: 'verifyEmail/:type/:userId/:token',
            element: <VerifyEmail />,
        },
    ]);
    return routes;
}