// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from 'firebase/storage';
import { getMessaging, onMessage } from "firebase/messaging";
import {getFunctions, httpsCallable} from "firebase/functions";
import {onAuthStateChanged,createUserWithEmailAndPassword,signInWithEmailAndPassword,getAuth,sendPasswordResetEmail,signOut,confirmPasswordReset } from "@firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDeYV2-GylGx9apj0sAM-rBbCARXqlsfps",
  authDomain: "vyanjan-b3af8.firebaseapp.com",
  databaseURL: "https://vyanjan-b3af8-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "vyanjan-b3af8",
  storageBucket: "vyanjan-b3af8.appspot.com",
  messagingSenderId: "812776017990",
  appId: "1:812776017990:web:0875f03c6bb63520c1fa47",
  measurementId: "G-0XX9M5SGVB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const messaging = getMessaging(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

export {storage,db,auth,messaging,onAuthStateChanged,createUserWithEmailAndPassword,signInWithEmailAndPassword,sendPasswordResetEmail,confirmPasswordReset,signOut,functions, app}