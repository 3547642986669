import { useNavigate } from 'react-router-dom';
import Footer from './footer';
import Header from './header';

const ShippingPolicy = () => {
    const navigate = useNavigate();
    const termsConditionsClick = () => {
        navigate(`/terms-and-conditions`);
      }
      const refundPolicyClick = () => {
        navigate(`/refund-policy`);
      }
  return (
    <div className="termsCondtion">
      <title> Shipping Policy | Vyanjan-Pursuit for Hunger! </title>
      <Header />
      <div className="container page-container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h2>Shipping Policy for VYANJAN</h2>
              <img src="assets/images/title-line.png" alt="title-line" className="img-fluid" />
            </div>
            <p >
            At Vyanjan, we believe in a direct connection between food makers and food seekers! This Shipping & Delivery Policy is an integral component of our Terms of Service ("Terms"). It should be read in conjunction with our main Terms, specifically the Terms and Conditions.  <a className="nav-link" style={{ display: "inline-block" }} href='' onClick={(e) => { termsConditionsClick() }}>
            Terms and  Conditions
                </a> 
            </p>
            <p >
            Please  thoroughly  examine  our  Shipping  &  Delivery  Policy  before  making  any  orders through the Vyanjan App, as this policy is applicable to all orders you place with us.
            </p>
            <div className="blog-hover">
              <h4>Delivery Zones</h4>
            </div>
            <p >
            We presently deliver to our area. Please stay tuned as we're continuously expanding our delivery zones, if your area isn't covered.
            </p>
            <div className="blog-hover">
              <h4>Delivery Options</h4>
            </div>
            <ol type="I">
              <li>Take Away</li>
              <p>The Food Seeker can use this option if he/she wants to pick up the order from the Food maker, in which case the address of the Food maker will be provided to the Food Seeker via Text Message.</p>
              <li>Door-step Delivery</li>
              <p>
              The Food Seeker can use this option if they want their food to be delivered at their home. Which will be managed by the food maker on his own.
              </p>
            </ol>
            <div className="blog-hover">
              <h4>Delivery Restrictions</h4>
            </div>
            <p >
            The Delivery might not be possible in few circumstances:
            </p>
            <ul>
              <li>If the location is too far, in which case the order will not be accepted.</li>
              <li>If the item you are trying to order is not in stock.</li>
            </ul>
            <div className="blog-hover">
              <h4>Real-Time Updates</h4>
            </div>
            <p >
            Track your order in real-time through the Vyanjan app.
            </p>
            <div className="blog-hover">
              <h4>Delivery Charges</h4>
            </div>
            <p >
            Delivery charges may vary based on location and order value. Check the app for applicable charges. As of now we are not charging any delivery charges as it is managed by the Food maker.
            </p>
            <div className="blog-hover">
              <h4>Delivery Delays</h4>
            </div>
            <p >
            In the event of a delay in delivery for any reason, we will promptly inform you and provide an updated estimated delivery date.
            </p>
            <div className="blog-hover">
              <h4>Packaging</h4>
            </div>
            <p >
            Your order will be carefully packaged to maintain the freshness and quality of your food. Sustainable packaging practices followed by the Vyanjan.
            </p>
            <div className="blog-hover">
              <h4>Special Instructions</h4>
            </div>
            <p >Have specific delivery instructions or allergies? Let us know during the checkout process, and we'll do our best to accommodate your requests.</p>
          <div className="blog-hover">
              <h4>Cancellation & Refund</h4>
            </div>
            <p >If you have questions about order cancellation and refund, please review our Policy at:  <a className="nav-link" style={{ display: "inline-block" }} href='' onClick={(e) => { refundPolicyClick() }}>
                  Refund Policy
                </a></p>
            <div className="blog-hover">
              <h4>Customer Support</h4>
            </div>
            <p >For any inquiries or assistance, our customer support team is available 24x7 to address your concerns. Reach us at  vyanjan12@gmail.com.</p>
          </div>
        </div >
      </div >
      <Footer />
    </div >
  )
}

export default ShippingPolicy