import { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate();
  const privacyPolicyClick = () => {
    navigate(`/privacy-policy`);
  }
  const termsConditionsClick = () => {
    navigate(`/terms-and-conditions`);
  }
  const refundPolicyClick = () => {
    navigate(`/refund-policy`);
  }
  const shippingPolicyClick = () => {
    navigate(`/shipping-policy`);
  }
  const customerDeleteAccountClick = () => {
    navigate(`/customer-delete-account-guide`);
  }
  const partnerDeleteAccountClick = () => {
    navigate(`/partner-delete-account-guide`);
  }
  const deleteAccountClick = () => {
    navigate(`/delete-account-guide`);
  }

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const goTopClick = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  window.addEventListener('scroll', toggleVisible);

  return (
    <div>
      <footer className="cpoy-right-bg" style={{ backgroundColor: '#f8f8fa' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <p className="copyright" style={{ display: "inline-block", marginBottom: "0px" }}>
                <a className="nav-link" style={{ display: "inline-block" }} href='' onClick={(e) => { privacyPolicyClick() }}>
                  Privacy Policy
                </a>
                <a className="nav-link" style={{ display: "inline-block" }} href='' onClick={(e) => { termsConditionsClick() }}>
                  Terms & Conditions
                </a>
                <a className="nav-link" style={{ display: "inline-block" }} href='' onClick={(e) => { refundPolicyClick() }}>
                  Refund Policy
                </a>
                <a className="nav-link" style={{ display: "inline-block" }} href='' onClick={(e) => { shippingPolicyClick() }}>
                  Shipping Policy
                </a>
                {/* <a className="nav-link" style={{ display: "inline-block" }} href='' onClick={(e) => { customerDeleteAccountClick() }}>
                  Customer Delete Account
                </a>
                <a className="nav-link" style={{ display: "inline-block" }} href='' onClick={(e) => { partnerDeleteAccountClick() }}>
                  Partner Delete Account
                </a> */}
                <a className="nav-link" style={{ display: "inline-block" }} href='' onClick={(e) => { deleteAccountClick() }}>
                  Delete Account Guide
                </a>
              </p>
            </div>
            <div className="col-md-12 text-center">
              <div className="social-footer">
                <ul style={{ padding: '0px', margin: "0px" }}>
                  <a target="_blank" href='https://www.facebook.com/healthyvegvyanjan'> <li ><i className="fa fa-facebook" aria-hidden="true" ></i></li></a>
                  <a target="_blank" href='https://instagram.com/healthyvegvyanjan?igshid=YmMyMTA2M2Y'><li ><i className="fa fa-instagram" aria-hidden="true"></i></li></a>
                </ul>
              </div>
            </div>
            <div className="col-md-12 text-center">
              <p className="copyright">Copyright @ 2023 G & S ENTREPRENEURS PRIVATE LIMITED</p>
            </div>
          </div>
        </div>
      </footer>
      <div className='tap-top' style={{ display: visible ? 'inline' : 'none' }} onClick={(e) => { goTopClick() }}>
        <div>
          <i aria-hidden='true' className='fa fa-angle-up' ></i>
        </div>
      </div>
    </div >
  )
}

export default Footer