import Footer from './footer';
import Header from './header';

const Landing = () => {

    return (
        <div>
            <title> Home | Vyanjan-Pursuit for Hunger! </title>
            <Header />
            <div className="bg-img">
                <section id="home" className='slide-bg'>
                    <div className="container">
                        <div className="row animation">
                            <div className="col-md-8">
                                <div className="d-flex-1">
                                    <div className="slide-text">
                                        <div>
                                            <b>
                                                <h1>Discover exciting vegetarian home cooked dishes made by local food makers.
                                                </h1>
                                            </b>
                                            <h4 style={{ textAlign: 'justify' }}></h4>
                                            <div className="slid-btn">
                                                <a
                                                    href='https://play.google.com/store/apps/details?id=com.gns.vyanjan'
                                                    target="_blank">
                                                    <img src="assets/images/app1.png" alt="app1"
                                                        className="img-fluid" data-tilt data-tilt-perspective="50" data-tilt-speed="350"
                                                        data-tilt-max="1.8"
                                                        style={{
                                                            willChange: 'transform',
                                                            transform: ' perspective(50px) rotateX(0deg) rotateY(0deg)'
                                                        }}
                                                    />
                                                </a>
                                                <a target="_blank" href='https://apps.apple.com/in/app/vyanjan/id6446190678'>
                                                    <img src="assets/images/app2.png" alt="app2"
                                                        className="img-fluid" data-tilt data-tilt-perspective="50" data-tilt-speed="350"
                                                        data-tilt-max="1.8" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="home-right">
                                    <div className="mobile-slid">
                                        <img src="assets/images/image1.png" alt="top1" className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='animation-circle'>
                        <i></i>
                        <i></i>
                        <i></i>
                    </div>
                </section>

                <section id="about" className="about" >
                    <div className="about-chat">
                        <div className="container ">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <div className="section-title">
                                        <h2>About Vyanjan</h2>
                                        <img src="assets/images/title-line.png" alt="title-line" className="img-fluid" />
                                    </div>
                                </div>
                                <div >
                                    <div className='col-md-12'>
                                        <div className='chat-slide'>
                                            <h3>
                                                Discover and taste authentic Indian vegetarian cuisine and home cooked dishes made by local food makers.
                                            </h3>
                                            <p>Vyanjan was born out of a deep understanding of the emotional connection that food has to
                                                our sense of home and belonging. Our founder was pregnant and craving for vegetarian
                                                home-cooked meals while traveling, struggling to find the comfort she needed. That's when
                                                she realized there had to be a better way. And thus, Vyanjan was born - a platform that
                                                connects you with local cooks who prepare authentic, homemade meals with love and care.
                                                We believe that food should bring us joy, comfort, and a sense of home, no matter where we
                                                are in the world.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                <section id="vision" className="vision" >
                    <div className="about-chat">
                        <div className="container ">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <div className="section-title">
                                        <h2>Our Vision</h2>
                                        <img src="assets/images/white-line.png" alt="white-line" className="img-fluid" />
                                    </div>
                                </div>
                                <div >
                                    <div className='col-md-12'>
                                        <div className='chat-slide'>
                                            <p>To create a world where healthy, home-cooked vegetarian food is accessible from our local
                                                home cooks to all, regardless of their location, while working towards eliminating hunger
                                                worldwide.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                <section id="mission" className="about" >
                    <div className="about-chat">
                        <div className="container ">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <div className="section-title">
                                        <h2>Our Mission:</h2>
                                        <img src="assets/images/title-line.png" alt="title-line" className="img-fluid" />
                                    </div>
                                </div>
                                <div >
                                    <div className='col-md-12'>
                                        <div className='chat-slide'>
                                            <p>Our mission is to create a platform for people to share their home-cooked vegetarian recipes
                                                with the world. We only use fresh, natural ingredients to make healthy and tasty food that
                                                satisfies both body and soul. Our founder is all about crushing hunger, so we&#39;re committed to
                                                ending food insecurity in our hood by spending some of our profits on helping those in need.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                <section className="theme-bg feature" id="feature" >
                    <div className="container">
                        <div className="animation-circle-inverse">
                            <i></i>
                            <i></i>
                            <i></i>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="section-title">
                                    <h2 className="text-white">Vyanjan Features</h2>
                                    <img src="assets/images/white-line.png" alt="white-line" className="img-fluid" />
                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-6">
                                <div className="future-box">
                                    <div className="future-timeline">
                                        <ul >
                                            <li className="timeline">
                                                <h4 className="sub-title">Authentic dishes Menu</h4>
                                                <p>Wide variety homemade meals</p>
                                            </li>
                                            <li className="timeline">
                                                <h4 className="sub-title">Local Home Chefs</h4>
                                                <p>Local culinary experts</p>
                                            </li>
                                            <li className="timeline">
                                                <h4 className="sub-title">Easy to use interface
                                                </h4>
                                                <p>Intuitive interface</p>
                                            </li>
                                            <li className="timeline">
                                                <h4 className="sub-title"> User opinions</h4>
                                                <p>Reviews and ratings feature</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 future-mobile" >
                                <img src="assets/images/image2.png" alt="feature-mob" className='img-fluid' />
                            </div>

                            <div className="col-lg-4 col-sm-6">
                                <div className="future-box">
                                    <div className="future-timeline-right">
                                        <ul className="text-left" >
                                            <li className="timeline-right">
                                                <h4>Flexible delivery choices</h4>
                                                <p>Varied shipping options</p>
                                            </li>
                                            <li className="timeline-right">
                                                <h4>Secure payment options</h4>
                                                <p>Safe and hassle-free payment processing</p>
                                            </li>
                                            <li className="timeline-right">
                                                <h4>Ingredient transparency
                                                </h4>
                                                <p>Ingredient disclosure</p>
                                            </li>
                                            <li className="timeline-right">
                                                <h4>Social media connectivity</h4>
                                                <p>Social media integration</p>
                                            </li>
                                            <li className="timeline-right">
                                                <h4>Refer and Earn </h4>
                                                <p>Make money by sharing app</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="foodMaker" className="foodMaker">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 cotext-center">
                                <div className="section-title">
                                    <h2>Register as Vyanjan Maker</h2>
                                    <img src="assets/images/title-line.png" alt="title-line" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-12 text-center">
                                <p style={{ textAlign: 'justify' }}>
                                    As a Food Maker on our app, you have the opportunity to showcase your culinary skills and
                                    passion for cooking to a wider audience. By preparing delicious homemade meals for
                                    customers in your area, you can earn extra income while sharing your love of food with
                                    others. Our platform makes it easy and convenient for you to connect with customers,
                                    receive orders, and get paid for your services. Join our community of talented Food Makers
                                    today and start sharing your delicious creations with the world!
                                </p>
                                <div className="my-2">
                                    < h3 >Steps to Register</h3>
                                    <div className="container text-center"><div className="row">
                                        <div className="col-md-4">
                                            <div className="d-flex justify-content-center"><h4 className='rounded-number'>1</h4></div>
                                            <div className="text-center my-3">
                                                <img src="assets/images/image3.png" alt="top1" className="img-fluid" />
                                                <p className="text-center steps">Get the app <br /> downloaded</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="d-flex justify-content-center"><h4 className='rounded-number'>2</h4></div>
                                            <div className="text-center my-3">
                                                <img src="assets/images/image4.png" alt="top1" className="img-fluid" />
                                                <p className="text-center steps">Sign up and receive <br />approval</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="d-flex justify-content-center"><h4 className='rounded-number'>3</h4></div>
                                            <div className="text-center my-3">
                                                <img src="assets/images/image5.png" alt="top1" className="img-fluid" />
                                                <p className="text-center steps"> Start serving the <br /> community</p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <h3 >Create your page on VyanjanMaker App</h3>
                                <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                                    <a style={{ paddingRight: '10px' }} target="_blank" href='https://play.google.com/store/apps/details?id=com.gns.vyanjanmaker'>
                                        <img src="assets/images/app1.png" alt="app1"
                                            className="img-fluid" data-tilt data-tilt-perspective="50" data-tilt-speed="350"
                                            data-tilt-max="1.8"
                                            style={{ border: '1px solid #4D4D4D', borderRadius: '5px', height: '39px' }} />
                                    </a>
                                    <a href='https://apps.apple.com/in/app/vyanjanmaker/id6446190507' target="_blank">
                                        <img src="assets/images/app2.png" alt="app2"
                                            className="img-fluid" data-tilt data-tilt-perspective="50" data-tilt-speed="350"
                                            style={{ border: '1px solid #4D4D4D', borderRadius: '5px', height: '39px' }}
                                            data-tilt-max="1.8" />
                                    </a>
                                </div>
                                <p>
                                    <h4 style={{ textAlign: 'center' }}>
                                        Discover and taste authentic Indian vegetarian cuisine and home cooked dishes made by local food makers.
                                    </h4>
                                </p>
                            </div>
                        </div>
                    </div>
                </section >

                <section className="download-bg" >
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-2 display-flex">
                                <div className="footer-logo">
                                    <img src="/vyanjanLogo.png" style={{ width: "100px", height: "100px", background: "rgba(255, 255, 255, 0.9)", borderRadius: "50%", padding: "5px" }} alt="footer-logo"
                                        className="img-fluid footer-logo-img" />
                                </div>
                            </div>
                            <div className="col-xl-6 display-flex" >
                                <div className="download-text">
                                    <h3>Download the Vyanjan app and enjoy it</h3>
                                </div>
                            </div>
                            <div className="col-xl-4 display-flex text-right"  >
                                <div className="slide-btn" style={{ paddingRight: '15px' }}>
                                    <a
                                        href='https://play.google.com/store/apps/details?id=com.gns.vyanjan'
                                        target="_blank">
                                        <img src="assets/images/app1.png" alt="app1"
                                            className="img-fluid" data-tilt data-tilt-perspective="50" data-tilt-speed="350"
                                            data-tilt-max="1.8"
                                        />
                                    </a>
                                </div>
                                <a target="_blank" href='https://apps.apple.com/in/app/vyanjan/id6446190678'>
                                    <img src="assets/images/app2.png" alt="app2"
                                        className="img-fluid" data-tilt data-tilt-perspective="50" data-tilt-speed="350"
                                        data-tilt-max="1.8" />
                                </a>
                            </div>
                        </div>
                    </div>
                </section >

                <section className="contact" id="contact" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="section-title">
                                    <h2>Contact Us</h2>
                                    <img src="assets/images/title-line.png" alt="white-line" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-12 text-center">
                                <div className="contact-text">
                                    <h3>Email Us</h3>
                                    <p>vyanjan12@gmail.com</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </section >
            </div>
            <Footer />
        </div >
    )
}

export default Landing