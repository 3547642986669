import Footer from './footer';
import Header from './header';

const RefundPolicy = () => {
  return (
    <div className="termsCondtion">
      <title> Refund Policy | Vyanjan-Pursuit for Hunger! </title>
      <Header />
      <div className="container page-container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h2>Vyanjan Refund & Cancellation Policy</h2>
              <img src="assets/images/title-line.png" alt="title-line" className="img-fluid" />
            </div>
            <p>At VYANJAN, we prepare every dish with care and pride, using only the finest and
              freshest ingredients. If we make a mistake with your order, such as omitting an item or
              getting something wrong, we are happy to provide a replacement or refund upon
              request. However, we typically do not offer refunds for food that a customer simply does
              not like.</p>
            <p>Once your order is prepared, we cannot cancel it or offer refunds or store credit if you
              change your mind or order something by mistake. We also cannot offer refunds or store
              credit for non-food items or beverages</p>
            <div className="blog-hover">
              <ol type="I">
                <h4><li> PAYMENTS</li></h4>
                <ol type="A">
                  <p><li>In case of payment at the time of delivery, the user shall not be required to
                    pay for:</li></p>
                  <ol type="a">
                    <p><li>Orders where the packaging has been tampered or damaged by
                      us;</li></p>
                    <p><li>Wrong order being delivered; or</li></p>
                    <p><li>Items missing from your order at the time of delivery</li></p>
                  </ol>
                </ol>
                <h4><li>REFUNDS</li></h4>
                <ol type="A">
                  <p><li>The user shall be entitled to a refund only if you prepay for your order at
                    the time of placing your order on the Platform and only in the event of any
                    of the following circumstances:</li></p>
                  <ol type="1">
                    <p><li>The order packaging has been tampered or damaged at the time of
                      delivery;</li></p>
                    <p><li>The user cancels the order due to:</li></p>
                    <ol type="a">
                      <p><li>Your delivery location following outside our designated
                        delivery zones;</li></p>
                      <p><li>Failure to contact you by phone or email at the time of
                        confirming the order booking; or</li></p>
                      <p><li>Where the user cancels the order at the time of confirmation
                        due to unavailability of the items you ordered for at the time
                        of booking.</li></p>
                    </ol>
                  </ol>
                  <p><li>The decision on refunds shall be at the sole discretion of the Food
                    Delivery Platform and shall be final and binding.</li></p>
                  <p><li>All refund amounts shall be credited to your account within 3-4 business
                    days in accordance with the terms that may be stipulated by the bank
                    which has issued the credit/debit card.</li></p>
                </ol>
              </ol>
            </div>
            <div className="blog-hover">
              <h4>ORDER CANCELLATION</h4>
            </div>
            <p >
              At VYANJAN, we prepare every dish with care and pride, using only the finest and
              freshest ingredients. If we make a mistake with your order, such as omitting an item or
              getting something wrong, we are happy to provide a replacement or refund upon request. However,
              we typically do not offer refunds for food that a customer simply doesnot like. The cancellation
              can be possible if one of the following:
            </p>
            <ul>
              <li>
                <p >
                  An order can be cancelled before the Food Maker confirms and accepts the
                  order.To cancel an order, tap &quot;Cancel Order&quot; in application.
                </p>
              </li>
              <li>
                <p >
                  If you would like to cancel your order after the Food Maker has accepted it, you
                  will be charged for the cancellation fee which was decided by the application.
                </p>
              </li>
              <li>
                <p >
                  In the event that the Food Maker is unable to fulfill an order due to unforeseen
                  circumstances, we reserve the right to cancel the order and provide a full refund
                  to the original payment method.
                </p>
              </li>
              <li>
                <p >
                  If a delivery partner is unable to deliver your order due to reasons such as an
                  incorrect address provided or failure to provide access to the delivery location,
                  we reserve the right to cancel the order and charge a cancellation fee.
                </p>
              </li>
              <li>
                <p >
                  If an order is cancelled due to the unavailability of the requested food item(s),
                  we will refund the full amount paid to the original payment method used.
                </p>
              </li>
              <li>
                <p >
                  If an order is cancelled by the Food Maker or delivery partner due to reasons
                  beyond our control such as severe weather conditions, we will refund the full
                  amount paid to the original payment method used.
                </p>
              </li>
              <li>
                <p >
                  If you fail to pick up an order for a pickup order, the order will be cancelled and
                  no refund will be issued.
                </p>
              </li>
              <li>
                <p >
                  If you violate any terms and conditions of the food delivery application, we
                  reserve the right to cancel your order without providing a refund.
                </p>
              </li>
            </ul>
            <div className="blog-hover">
              <p><b>Note:</b> We reserve the right to update or modify this cancellation policy at any time. Any
                changes will be posted on our website and/or mobile application.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div >
  )
}

export default RefundPolicy