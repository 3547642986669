import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes';

export default function App() {

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  )
}

const container = document.getElementById('root');
const root = createRoot(container!) //if you use TypeScript
root.render(
  <App />
);
// If you want to enable client cache, register instead.
reportWebVitals();
