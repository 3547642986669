import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/');
    handleToggle();
  }

  const handleToggle = () => {
    let element: any = document.getElementById("mainmenu");
    if (element.classList.contains("show")) {
      element.classList.remove('show')
    } else {
      element.classList.add("show");
    }
  }

  const handleHeader = () => {
    let element: any = document.getElementById("pageheader");
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 60) {
      element.classList.add("darkHeader");
    } else {
      element.classList.remove('darkHeader')
    }
  }

  useEffect(() => {
    const onScroll = () => handleHeader();
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <nav id="pageheader" className="navbar navbar-expand-lg theme-nav fixed-top" style={{ background: '#ff891e' }}>
      <div className="container">
        <a href="/#home" className='navbar-brand'>
          <img style={{ height: "50px" }}
            src="/vyanjanLogo.png" alt="logo" />
          <img style={{ height: '40px' }}
            src="/vyanjanTagline.png" alt="logo" />
        </a>
        <button type='button' aria-expanded='false' aria-label='Toggle Navigation'
          data-toggle='collapse' data-target='#mainmenu'
          className='navbar-toggler'
          onClick={handleToggle}
        >
          <span className='navbar-toggler-icon'>
            <i className="fa fa-align-justify" aria-hidden="true"></i>
          </span>
        </button>
        <div id='mainmenu' className="collapse navbar-collapse float-right">
          <ul id='mymenu' className='navbar-nav'>
            <li className='nav-item'>
              <a className="nav-link" href="/#home" onClick={handleNavigate}>HOME</a>
            </li>
            <li className='nav-item'>
              <a className="nav-link" href="/#about" onClick={handleNavigate}>ABOUT US</a>
            </li>
            {/* <li className='nav-item'>
              <a className="nav-link" href="/#vision" onClick={handleNavigate}>VISION</a>
            </li> */}
            <li className='nav-item'>
              <a className="nav-link" href="/#feature" onClick={handleNavigate}>FEATURES</a>
            </li>
            <li className='nav-item'>
              <a className="nav-link" href="/#foodMaker" onClick={handleNavigate}><b>REGISTER AS VYANJAN MAKER</b></a>
            </li>
            <li className='nav-item'>
              <a className="nav-link" href="/#contact" onClick={handleNavigate}>CONTACT US</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

  )
}

export default Header