import React from 'react'
import Header from '../../header'
import Footer from '../../footer'
import './DeleteAccount.css'

const DeleteAccount = () => {
    return (
        <div className="termsCondtion">
            <title> Delete Account Guide | Vyanjan-Pursuit for Hunger! </title>
            <Header />
            <div className="container page-container">
                <div id="content">
                    <div id="getstarted-top">
                        <h3>Steps to Delete Account</h3>
                        It is very painful to see you go. However, we are here to help you out. First of all please complete all your
                        pending orders if any before processing further.
                    </div>
                </div>
                <div id="content" className="container-delete">
                    <b>Deleting your account will delete the following,</b>
                </div>
                <div className="container-delete row">
                    <div className='col-6'>
                        <b>Vyanjaners App:</b>
                        <ol style={{ listStyle: "square" }}>
                            <li>Delete your wallet amount, rewards & paymnet history</li>
                            <li>Delete your order & order history</li>
                            <li>Delete your account from Vyanjan</li>
                        </ol>
                    </div>
                    <div className='col-6'>
                        <b>Vyanjan Maker App:</b>
                        <ol style={{ listStyle: "square" }}>
                            <li>Delete your wallet amount, rewards & paymnet history</li>
                            <li>Delete your order & Bulk Order</li>
                            <li>Delete your product</li>
                            <li>Delete your bulk inquiry</li>
                            <li>Delete your account from Vyanjan</li>
                        </ol>
                    </div>
                </div>

                <div id="content">
                    <div id="getstarted">
                        <b>Please follow the below steps to delete your account</b>
                        <br /> <br />
                        Step 1. Open the Vyanjan app on your mobile phone. If the app is not installed, Please install the app
                        first using the link below. You can also visit the Google Play Store or Apple App Store and search Vynanjan to
                        install the app.
                        <div className='row'>
                            <div className='col-6'>
                                <b>Vyanjaners App Links</b>
                                <span style={{ display: "block", margin: "15px auto", maxWidth: "400px" }}>
                                    <a href="https://play.google.com/store/apps/details?id=com.gns.vyanjan" target="_blank">
                                        <img className='link-img-width' alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png" />
                                    </a>
                                </span>
                                <span style={{ display: "block", margin: "15px auto", maxWidth: "400px" }}>
                                    <a href="https://apps.apple.com/in/app/vyanjan/id6446190678" target="_blank">
                                        <img className='link-img-width' style={{ padding: "6px 0px;" }} alt="Get it on App Store" src="https://vyanjaners.com/assets/images/app-store.png" />
                                    </a>
                                </span>
                            </div>
                            <div className='col-6'>
                                <b>Vyanjan Maker App Links</b>
                                <span style={{ display: "block", margin: "15px auto", maxWidth: "400px" }}>
                                    <a href="https://play.google.com/store/apps/details?id=com.gns.vyanjanmaker" target="_blank">
                                        <img className='link-img-width' alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png" />
                                    </a>
                                </span>
                                <span style={{ display: "block", margin: "15px auto", maxWidth: "400px" }}>
                                    <a href="https://apps.apple.com/in/app/vyanjan-maker/id6446190507" target="_blank">
                                        <img className='link-img-width' style={{ padding: "6px 0px;" }} alt="Get it on App Store" src="https://vyanjaners.com/assets/images/app-store.png" />
                                    </a>
                                </span>
                            </div>
                        </div>

                        <br />
                        Step 2. Login into the app. If already login you will be redirected to the home screen in the app
                        {/* <br />
                        <img style={{ textAlign: 'center' }} alt="Vyanjan" height="600" src="https://vyanjaners.com/assets/images/delete-account-images/seeker/Seeker Login.jpg" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <img style={{ textAlign: 'center' }} alt="Vyanjan" height="600" src="https://vyanjaners.com/assets/images/delete-account-images/seeker/Seeker OTP.jpg" /> */}
                        <br /><br />
                        Step 3. Please click on bottom right button. It will redirect to the settings screen in the app
                        <br />
                        <div className='row'>
                            <div className='col-6'>
                                <b>Vyanjaners App</b><br />
                                <img className='img-size' alt="Vyanjan" src="https://vyanjaners.com/assets/images/delete-account-images/seeker/Seeker Order.jpg" />
                            </div>
                            <div className='col-6'>
                                <b>Vyanjan Maker App</b><br />
                                <img className='img-size' alt="Vyanjan" src="https://vyanjaners.com/assets/images/delete-account-images/maker/Maker Home.jpg" />
                            </div>
                        </div>

                        <br /><br />
                        Step 4. In the settings screen, Please click on <b>Delete Account</b>. Then one confirmation dialog will open. Click on <b>Yes</b>.
                        <br />
                        <div className='row'>
                            <div className='col-6'>
                                <b>Vyanjaners App</b><br />
                                <img className='img-size' alt="Vyanjan" src="https://vyanjaners.com/assets/images/delete-account-images/seeker/Seeker Profile.jpg" />
                            </div>
                            <div className='col-6'>
                                <b>Vyanjan Maker App</b><br />
                                <img className='img-size' alt="Vyanjan" src="https://vyanjaners.com/assets/images/delete-account-images/maker/Maker Profile.jpg" />
                            </div>
                        </div>
                        <br /><br />
                        <img className='img-size' alt="Vyanjan" src="https://vyanjaners.com/assets/images/delete-account-images/seeker/Seeker Dialog.jpg" />
                        <br /><br />It will redirect to the login screen. Now your account and data has been deleted.
                        <br /><br />You can sign up again if you wish to come back in future
                    </div>
                </div>
            </div>
            <Footer />
            {/* <div id="footer"> COPYRIGHT © 2024 G & S ENTREPRENEURS PRIVATE LIMITED. All Rights Reserved. </div> */}
        </div>
    )
}

export default DeleteAccount